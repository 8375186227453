<template>
  <div class="container">
    <div style="position: absolute; top: 65px; right: 20px">
      <a-button type="primary" class="ML12" @click="submit">确认</a-button>
      <a-button class="ML12" @click="$router.back()">取消</a-button>
    </div>
    <!--账户信息-->
    <UserTop :userInfo="userInfo"></UserTop>
    <a-form-model ref="form" :rules="rules" style="width: 500px; margin-top: 20px" :model="formData" :colon="false">
      <a-form-model-item label="入驻类型" prop="businessTag">
        <a-radio-group v-model="formData.businessTag" :defaultValue="1" :disabled="isedit==1">
          <a-radio :value="2">企业</a-radio>
          <a-radio :value="1">个人</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="店铺名称" prop="shopName">
        <a-input allowClear v-model="formData.shopName" placeholder="请输入" />
      </a-form-model-item>
      <a-form-model-item v-if="formData.businessTag === 2" label="企业名称" prop="companyName">
        <a-input allowClear v-model="formData.companyName" placeholder="请输入" />
      </a-form-model-item>
      <a-form-model-item label="入驻平台" prop="businessType">
        <a-select allowClear v-model="formData.businessType" placeholder="请选择" :disabled="isedit==1">
          <a-select-option :value="1">创作者平台</a-select-option>
          <a-select-option :value="2">独立店</a-select-option>
          <a-select-option :value="3">商家</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="状态" prop="applyState">
        <a-radio-group v-model="formData.applyState">
          <a-radio :value="0">审核中</a-radio>
          <a-radio :value="1">已通过</a-radio>
          <a-radio :value="2">未通过</a-radio>
        </a-radio-group>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
  import UserTop from '@/components/UserTop'
  import * as Settle from '@/api/user/settle'
  import {
    message
  } from 'ant-design-vue'

  export default {
    components: {
      UserTop
    },
    name: "insert",
    data() {
      return {
        userInfo: {},
        isedit: 0,
        formData: {
          businessTag: 1,
          applyState: 1,
          shopName: '',
          companyName: '',
          businessClass: '',
          businessType: '',
        },
        rules: {
          businessTag: [{
            required: true,
            message: "请选择入驻类型",
            trigger: "change"
          }],
          shopName: [{
            required: true,
            message: "请输入店铺名称",
            trigger: "blur"
          }],
          companyName: [{
            required: true,
            message: "请输入企业名称",
            trigger: "blur"
          }],
          businessClass: [{
            required: true,
            message: "请选择经营类型",
            trigger: "change"
          }],
          businessType: [{
            required: true,
            message: "请选择入驻平台",
            trigger: "change"
          }]
        }
      }
    },
    created() {
      this.userId = this.$route.query.id;
      this.phone = this.$route.query.phone;
      this.isedit = this.$route.query.isedit || 0;
      if (this.isedit && this.isedit == 1) {
        this.$route.meta.title = '编辑入驻';
        this.querySettleById();
      } else {
        this.$route.meta.title = '新增入驻';
        this.queryHkyPersonal();
      }
    },
    methods: {
      queryHkyPersonal() {
        let phone = this.phone;
        Settle.queryHkyPersonal({
          phone: phone
        }).then(res => {
          const data = res.data || {}
          this.userInfo = data
        }).finally(() => {
          this.loading = false
        })
      },
      querySettleById() {
        Settle.querySettleById(this.userId).then(res => {
          const data = res.data || {}
          this.formData = data || {}
          this.userInfo = data.personalResp || {}
        }).finally(() => {
          this.loading = false
        })
      },
      submit() {
        let data = {
          userId: this.userInfo.userId,
          businessTag: this.formData.businessTag,
          businessType: this.formData.businessType,
          companyName: this.formData.companyName,
          shopName: this.formData.shopName,
          applyState: this.formData.applyState,
          businessClass: this.formData.businessClass,
        }
        if (this.formData.shopName === '') {
          return message.error('请输入店铺名称')
        }
        if (this.formData.businessType === '') {
          return message.error('请选择入驻平台')
        }
        let that = this;
        if (this.isedit == 1) {
          data = {
            id: this.formData.id,
            shopName: this.formData.shopName,
            companyName: this.formData.companyName,
            applyState: this.formData.applyState
          };
          Settle.editSettleUser(data).then(res => {
            if (res.code === 0) {
              message.success(res.message);
              that.$router.back()
            }
          }).finally(() => {
            that.loading = false
          })
        } else {
          Settle.confirmSettleUser(data).then(res => {
            if (res.code === 0) {
              message.success(res.message);
              that.$router.back()
            }
          }).finally(() => {
            that.loading = false
          })
        }
      }
    }
  }
</script>

<style scoped lang="less">
  .container {
    padding: 20px;
    background: #ffffff;

    .label {
      width: 100px;
    }
  }

  .flex-start {
    display: flex;
    justify-content: flex-start;
  }

  .margin-bottom {
    margin-bottom: 20px;
  }

  /deep/.ant-form-item-required {
    font-size: 18px;
    font-weight: bold;
  }
</style>
